/*
 * Core rendering and configuration parameters model / state
 */

import { quat, vec3, vec4 } from "gl-matrix";
import { reactive } from "vue";

//export const BACKGROUND_COLOR = vec4.fromValues(0.005, 0.053, 0.103, 1);
export const BACKGROUND_COLOR = vec4.fromValues(0,0,0, 1);

export type CameraParams = {
    orbitSpeed: number,
    fov: number,
};

export const cameraParams = reactive<CameraParams>({
    orbitSpeed: 0.25,
    fov: 75,//90,
});

export const camera = {
    offset: vec3.fromValues(0, 0, 0),
    rotation: quat.fromValues(0.29, 0.25, 0.05, 0.95),
    distance: 20000.0,
    zoomMultiplier: 1.0,
    trackRatio: 0.0,
};

export let currentTime = 0.0;
export function setCurrentTime(t: number) {
    currentTime = t;
}

export type NodeVisualisationParams = {
    bobAmount: number,
    bobSpeed: number,
    swimAmount: number,
    swimSpeed: number,
    pulseAmount: number,
    pulseSpeed: number,
    depthRange: number,
    depthSharpness: number,
    numNodes: number,
    timelinePosition: number,
}

export const nodeVisualisationParams = reactive<NodeVisualisationParams>({
    bobAmount: 16.0,
    bobSpeed: 1.49,
    swimAmount: 0.0,
    swimSpeed: 1.0,
    pulseAmount: 0.06,
    pulseSpeed: 2.36,
    depthRange: 100000.0,
    depthSharpness: 1,
    numNodes: 1000000,
    timelinePosition: 0,
});

export type NebulaVisualisationParams = {
    intensity: number,
    sharpness: number,
    scale: number,
    texture: number,
    maxGeneration: number,
    minGeneration: number,
}

export const nebulaVisualisationParams = reactive<NebulaVisualisationParams>({
    intensity: 0.3,
    sharpness: 5.0,
    scale: 10,//13.1,
    texture: 1.0,
    maxGeneration: 30.0,
    minGeneration: 1.0,
});

export type LayoutParams = {
    mode: "random"|"fan"|"icosahedron",
    showLines: "none"|"all"|"local",
    showHedron: boolean,
    
    randomiseChildPlacements: boolean,
    randomSeed: string,
    
    nodeSpacing: number,
    spacingStep: number,

    declusterMode: "depth"|"siblings"
    declusterAmount: number,
    declusterEase: number,
    
    nodeSize: number,
    nodeGenerationScaleFactor: number,

    spreadRate: number,
    spreadEase: number,
};

export const layoutParamsGlobal = {
    nodeSpacing: 6000,//5000,
    spacingStep: 0.45,//0.63,
    nodeSize: 700.0, //659.0,

    spreadRate: 0,//0.0000175,
    spreadEase: 2.0,
    nodeGenerationScaleFactor: 0.4,//0.36,
};

export const layoutParamsLocal = {
    /*
    nodeSpacing: 6000,
    spacingStep: 0.385,
    nodeSize: 927.0,

    spreadRate: 0,//0.0000175,
    spreadEase: 2.0,
    nodeGenerationScaleFactor: 0.36,
    */
   ...layoutParamsGlobal
};

export const layoutParams = reactive<LayoutParams>({
    mode: "fan",
    showLines: "local",
    showHedron: false,
    
    randomiseChildPlacements: true,
    randomSeed: "blueprint",
    
    declusterMode: "siblings",
    declusterAmount: 10,
    declusterEase: 1,

    ...layoutParamsGlobal
});
