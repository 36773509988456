import { mat4, vec3 } from "gl-matrix";
import { BACKGROUND_COLOR } from "../rendering_state";

export type Renderer<DrawFunc> = {
    prepare: (proj: mat4)=>void,
    draw: DrawFunc,
};

export function initOpenGl(canvas: HTMLCanvasElement) {
    const gl = canvas.getContext("webgl2");

    // Only continue if WebGL is available and working
    if (!gl) {
        throw new Error("Unable to initialize WebGL. Your browser or machine may not support it.")
    }

    initSharedRendererData(gl);

    gl.enable(gl.BLEND);
    gl.clearColor(BACKGROUND_COLOR[0], BACKGROUND_COLOR[1], BACKGROUND_COLOR[2], BACKGROUND_COLOR[3]);

    return gl;
}

export let QUAD_VERTEX_BUFFER: WebGLBuffer|null = null;

function initSharedRendererData(gl: WebGLRenderingContext) {
    QUAD_VERTEX_BUFFER = gl.createBuffer();
    if (!QUAD_VERTEX_BUFFER) throw new Error(`Failed to create new quad vertex buffer`);

    gl.bindBuffer(gl.ARRAY_BUFFER, QUAD_VERTEX_BUFFER);

    const vertices = new Float32Array([
        -0.5, -0.5,      0, 0,
        0.5,  -0.5,      1, 0,
        0.5,  0.5,       1, 1,
        -0.5, 0.5,       0, 1
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
}

export function compileShader(gl: WebGLRenderingContext, text: string, type: number) {
    const shader = gl.createShader(type);
    if (!shader) throw new Error(`Failed to compile shader: Could not create new shader`);

    gl.shaderSource(shader, text);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) throw new Error(`Failed to compile shader: ${gl.getShaderInfoLog(shader)}`);

    return shader;
}

export function compileProgram(gl: WebGLRenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader) {
    const program = gl.createProgram();
    if (!program) throw new Error(`Failed to compile program: Could not create new program`);

    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);

    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) throw new Error(`Failed to compile program: ${gl.getProgramInfoLog(program)}`);

    return program;
}

export enum VertexAttributeType {
    VEC2F, VEC3F, VEC4F,
    FLOAT,
}

export type VertexAttribute = {
    location: number,
    type: VertexAttributeType
}
export const FLOAT_SIZE_BYTES = 4;

export function bindVertexAttributes(gl: WebGL2RenderingContext, buffer: WebGLBuffer, isInstanced: boolean, attributes: VertexAttribute[]) {
    const totalSizeBytes = getVertexAttributesTotalFloatCount(attributes) * FLOAT_SIZE_BYTES;

    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);

    let offset = 0;
    for (const attrib of attributes) {
        gl.enableVertexAttribArray(attrib.location);
        switch (attrib.type) {
            case VertexAttributeType.VEC2F: gl.vertexAttribPointer(attrib.location, 2, gl.FLOAT, false, totalSizeBytes, offset); break;
            case VertexAttributeType.VEC3F: gl.vertexAttribPointer(attrib.location, 3, gl.FLOAT, false, totalSizeBytes, offset); break;
            case VertexAttributeType.VEC4F: gl.vertexAttribPointer(attrib.location, 4, gl.FLOAT, false, totalSizeBytes, offset); break;
            case VertexAttributeType.FLOAT: gl.vertexAttribPointer(attrib.location, 1, gl.FLOAT, false, totalSizeBytes, offset); break;
        }
        
        gl.vertexAttribDivisor(attrib.location, isInstanced ? 1 : 0);

        offset += getFloatCount(attrib.type)*FLOAT_SIZE_BYTES;
    }
}

export function getVertexAttributesTotalFloatCount(attributes: VertexAttribute[]) {
    let totalSize = 0;
    for (const attrib of attributes) {
        totalSize += getFloatCount(attrib.type);
    }
    return totalSize;
}

function getFloatCount(type: VertexAttributeType) {
    switch (type) {
        case VertexAttributeType.VEC2F: return 2;
        case VertexAttributeType.VEC3F: return 3;
        case VertexAttributeType.VEC4F: return 4;
        case VertexAttributeType.FLOAT: return 1;
    }
}

export function enableBlendSettings(gl: WebGL2RenderingContext, mode: "additive"|"alpha", depthTest: boolean, depthWrite: boolean) {
    if (mode == "additive") {
        gl.blendFunc(gl.ONE, gl.ONE);    
    } else {
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
    }
    
    if (depthTest) {
        gl.enable(gl.DEPTH_TEST);
        gl.depthFunc(gl.LEQUAL);
    } else {
        gl.disable(gl.DEPTH_TEST);
    }

    gl.depthMask(depthWrite);
}

export async function loadImage(path: string) {
    return await createImageBitmap(await (await fetch(path)).blob(), {imageOrientation: "flipY"});
}

export function worldToScreen(worldCoord: vec3, projViewMat: mat4, canvas: HTMLCanvasElement) {
    const screenCoord = vec3.create();
    vec3.transformMat4(screenCoord, worldCoord, projViewMat);
    
    if (screenCoord[2]>1.0) return null; // Behind the camera!

    return vec3.fromValues(
        Math.round(canvas.clientWidth*(screenCoord[0]+1.0)/2),
        Math.round(canvas.clientHeight*(screenCoord[1]+1.0)/2),
        0,
    );
}

export async function generateTextImage(s: string) {    
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Unable to create text rendering canvas");

    // Prepare the font to be able to measure
    const fontSize = 13;
    const font = `${fontSize}px "M PLUS 1 Code", monospace`;
    ctx.font = font;
    
    const textMetrics = ctx.measureText(s);
    
    const width = textMetrics.width;
    const height = fontSize;
    
    // Resize canvas to match text size 
    canvas.width = width;
    canvas.height = height;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    
    // Re-apply font since canvas is resized.
    ctx.font = font;
    ctx.textAlign = "center" ;
    ctx.textBaseline = "middle";
    
    // Make the canvas transparent for simplicity
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    
    ctx.fillStyle = "white";
    ctx.fillText(s, width / 2, height / 2);
    
    const imageData = ctx.getImageData(0, 0, width, height);
    canvas.remove;

    return await createImageBitmap(imageData, {imageOrientation: "flipY"});
}