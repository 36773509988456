import { vec3, vec4 } from "gl-matrix";
import { calculateProjViewMatrixUI, rotateCamera, zoomCamera } from "./camera";
import { createGuiImageRenderer } from "./renderers/renderer_gui_image";
import { createGuiLineRenderer } from "./renderers/renderer_gui_line";
import { generateTextImage, loadImage } from "./renderers/renderer";
import { ArtworkBinding } from "./artwork";
import { clamp } from "@/core/utils";

export async function createUI(gl: WebGL2RenderingContext, canvas: HTMLCanvasElement, binding: ArtworkBinding, onInteraction: ()=>void) {
   
    // UI state
    let cursorPosition: vec3|null = null;
    let justClicked = false;
    let justMoved = false;
   
    const touches = new Map<number, PointerEvent>();

    // Create renderers
    const playButtonRenderer = createGuiImageRenderer(gl, await loadImage("/images/button_play.png"), 0.5);
    const pauseButtonRenderer = createGuiImageRenderer(gl, await loadImage("/images/button_pause.png"), 0.5);
    const trackbarPipRenderer = createGuiImageRenderer(gl, await loadImage("/images/trackbar_pip.png"), 0.5);
    const guiLineRenderer = createGuiLineRenderer(gl);
    
    // Define event listeners for user input
    canvas.addEventListener('wheel', (e)=> {
        onInteraction();
        zoomCamera(e.deltaY*0.0002);
    });

    function calculateCursorPosition(eX: number, eY: number) {
        const canvasRect = canvas.getBoundingClientRect();
        return vec3.fromValues(eX-canvasRect.left, canvasRect.bottom-eY, 0);
    }

    let prevPinchDistance = 0;
    function calculatePinchDistance() {
        let first: PointerEvent|null = null;
        for (const pointer of touches.values()) {
            if (first) {
                return Math.hypot(first.clientX - pointer.clientX, first.clientY - pointer.clientY);
            } else {
                first = pointer;
            }
        }
        return 0;
    }

    canvas.addEventListener('pointerdown', (e)=> {
        touches.set(e.pointerId, e);
        if (touches.size == 2) {
            prevPinchDistance = calculatePinchDistance();
        } else if (touches.size == 1) {
            cursorPosition = calculateCursorPosition(e.clientX, e.clientY);
            justClicked = true;
        }
    });
    canvas.addEventListener('click', (e)=> {
        if (e.buttons == 1) {
            cursorPosition = calculateCursorPosition(e.clientX, e.clientY);
            justClicked = true;
        }
    });
    canvas.addEventListener('pointerup', (e)=> {
        touches.delete(e.pointerId);
        if (touches.size == 0) {
            cursorPosition = null;
            binding.scrubbingTrackbar = false;
        }
    });
    canvas.addEventListener('pointercancel', (e)=> {
        touches.delete(e.pointerId);
    });
    canvas.addEventListener('pointermove', (e)=> {
        touches.set(e.pointerId, e);
        if (touches.size > 1) {
            cursorPosition = null;
        } else {
            cursorPosition = calculateCursorPosition(e.clientX, e.clientY);
            justMoved = true;
        }

        if (touches.size == 2) {
            // Pinch to zoom
            onInteraction();
            const pinchDistance = calculatePinchDistance();
            zoomCamera((prevPinchDistance-pinchDistance)*0.002);
            prevPinchDistance = pinchDistance;
        } else if (e.buttons == 1) {
            // Left-click/drag to move
            onInteraction();
            if (!binding.scrubbingTrackbar) {
                rotateCamera(e.movementX, e.movementY);
            }
        } else if (e.buttons == 0) {
            binding.scrubbingTrackbar = false;
        }
    }, false);

    // Return UI update/render function
    return () => {
        if (!binding.showUi) return;

        const projViewMatrixUI = calculateProjViewMatrixUI(canvas);

        const trackbarWidth = canvas.clientWidth*0.6;
        const trackbarHeight = 20;
        const trackbarBottom = Math.min(canvas.clientHeight*0.13, canvas.clientWidth*0.13, 80)+50;
        const trackbarTop = trackbarBottom+trackbarHeight;
        const trackbarMidY = (trackbarBottom+trackbarTop)/2;
        const trackbarLeft = (canvas.clientWidth-trackbarWidth)/2;
        const trackbarRight = trackbarLeft+trackbarWidth;
        const trackbarHovered = cursorPosition &&
                                cursorPosition[0] >= trackbarLeft && cursorPosition[0] <= trackbarRight &&
                                cursorPosition[1] >= trackbarBottom-10 && cursorPosition[1] <= trackbarTop+10;
            
        const playButtonPosition = vec3.fromValues(trackbarLeft-30, trackbarMidY, 0);
        const playButtonHovered = cursorPosition && vec3.dist(cursorPosition, playButtonPosition) < 25;

        if (justClicked) {
            if (trackbarHovered) {
                binding.scrubbingTrackbar = true;
            } else if (playButtonHovered) {
                binding.timelinePlaying = !binding.timelinePlaying;
            }
        }

        if (binding.scrubbingTrackbar && (justClicked || justMoved)) {
            binding.timelinePosSeconds = binding.timelineMaxSeconds * clamp((cursorPosition![0]-trackbarLeft)/trackbarWidth, 0, 1);
        }

        const TRACKBAR_COLOUR = vec4.fromValues(0.2, 0.2, 0.2, 1.0);
        guiLineRenderer.prepare(projViewMatrixUI);
        guiLineRenderer.draw(vec3.fromValues(trackbarLeft, trackbarBottom, 0),  vec3.fromValues(trackbarLeft, trackbarTop, 0), TRACKBAR_COLOUR);
        guiLineRenderer.draw(vec3.fromValues(trackbarRight, trackbarBottom, 0), vec3.fromValues(trackbarRight, trackbarTop, 0), TRACKBAR_COLOUR);
        guiLineRenderer.draw(vec3.fromValues(trackbarLeft, trackbarMidY, 0), vec3.fromValues(trackbarRight, trackbarMidY, 0), TRACKBAR_COLOUR);
    
        const timelineProgress = clamp(binding.timelinePosSeconds!/binding.timelineMaxSeconds, 0, 1);
        trackbarPipRenderer.prepare(projViewMatrixUI);
        trackbarPipRenderer.draw(vec3.fromValues(trackbarLeft + trackbarWidth*timelineProgress, trackbarMidY, 0), 1.0);

        const buttonRenderer = binding.timelinePlaying ? pauseButtonRenderer : playButtonRenderer;
        buttonRenderer.prepare(projViewMatrixUI);
        buttonRenderer.draw(playButtonPosition, playButtonHovered ? 1.0 : 0.7);

        justClicked = false;
        justMoved = false;
    }
}