/*
 * Calculates node positions in world space by applying the specified layout algorithm to the entire tree of participants
 */

import { mat4, quat, vec3 } from "gl-matrix";
import { Participant } from "../participant_model"
import { NodeInstanceData } from "../renderers/renderer_node"
import { Hedron, fan, generateHedronPoint, icosahedron } from "./hedron";
import { clamp, shuffle } from "@/core/utils";
import seedrandom from "seedrandom";
import { layoutParams, layoutParamsGlobal, layoutParamsLocal } from "../rendering_state";

export function layoutNodes(participants: Participant[], onProgress: (readyNodes: NodeInstanceData[])=>void) {
    switch (layoutParams.mode) {
        case "random":      return layoutRandomly(participants, onProgress);
        case "fan":         return layoutHedron(participants, icosahedron, fan, layoutParams.randomiseChildPlacements, onProgress);
        case "icosahedron": return layoutHedron(participants, icosahedron, icosahedron, true, onProgress);
    }
}

function layoutRandomly(participants: Participant[], onProgress: (readyNodes: NodeInstanceData[])=>void) {
    const nodes:NodeInstanceData[] = [];

    for (let idx=0; idx<participants.length; idx++) {
        const randomPos = idx==0 ? vec3.create() : vec3.fromValues(
            (Math.random()-0.5)*10000,
            (Math.random()-0.5)*10000,
            (Math.random()-0.5)*10000
        );

        nodes.push({
            globalViewPos: randomPos,
            localViewPos: randomPos,
            colour: vec3.fromValues(...participants[idx].colour),
            generation: 0,
            joinedTime: participants[idx].joined,
            parentIdx: participants[idx].parent,
        });
    }

    onProgress(nodes);

    return ()=>{ null; };
}

function layoutHedron(participants: Participant[], rootHedron: Hedron, childHedron: Hedron, skipFirstChildVertex: boolean, onProgress: (readyNodes: NodeInstanceData[])=>void) {
    const nodes:NodeInstanceData[] = [];

    // Keeps track of metadata about each node, which we need during the layout calculation
    type Meta = {
        depth: number,
        numDirectChildren: number,
        numPlacedChildren: number,
        maxChildDepth: number,
        orient?: quat,
        declusteredDepth?: number,
    };
    const meta:Meta[] = [];

    const startTime = new Date();

    // Root node - position in the center
    meta.push({
        depth: 0,
        numDirectChildren: 0,
        numPlacedChildren: 0,
        maxChildDepth: 0,
        orient: eulerQuat(0,0,0),
        declusteredDepth: 0
    });
    nodes.push({
        globalViewPos: vec3.fromValues(0,0,0),
        localViewPos: vec3.fromValues(0,0,0),
        colour: vec3.fromValues(...participants[0].colour),
        generation: 0,
        joinedTime: -999999999,
        parentIdx: null
    });
    
    // Index children / depth
    function indexTree() {
    
        let maxDirectChildren = 0;
        let maxDepth = 0;

        for (let participantId=1; participantId<participants.length; ++participantId) {
            if (terminated) return;

            const p = participants[participantId];

            const parent = meta[p.parent!];
            const depth = parent.depth+1;
            parent.numDirectChildren++;
            meta.push({depth: depth, numDirectChildren:0, numPlacedChildren: 0, maxChildDepth: 0});

            maxDirectChildren = Math.max(parent.numDirectChildren, maxDirectChildren);
            maxDepth = Math.max(depth, maxDepth);

            let ancestorIdx:number|null = p.parent;
            while (ancestorIdx != null) {
                const ancestor = meta[ancestorIdx];
                ancestor.maxChildDepth = Math.max(depth-ancestor.depth, ancestor.maxChildDepth);
                ancestorIdx = participants[ancestorIdx].parent;
            }
        }

        console.log("Max Children", maxDirectChildren, "Max Depth", maxDepth);

        setTimeout(processNextBatch, 0);
    }

    // Calculate positions
    let participantId=1;

    let terminated = false;
    const batchSize = 1000;
    function processNextBatch() {
        
        const processUpTo = Math.min(participantId + batchSize, participants.length);
    
        while (participantId < processUpTo) {
            if (terminated) return;

            const p = participants[participantId];
            
            // Position on hedron centered around parent node
            const parent = meta[p.parent!];
            const hedron = parent.depth == 0 ? rootHedron : childHedron;

            // Determine which point index on the hedron this node will use
            let hedronPointIndex = parent.numPlacedChildren;

            // Skip-first-vertex option is provided because typically we don't want to spawn a child which points directly "back towards" its grandparent.
            // So to guarantee this we can define a hedron where the first vertex points in that "backwards" direction, but then never use it.
            // We don't need to do this when placing direct children of the root node, though, as they have no grandparents.
            if (parent.depth > 0 && skipFirstChildVertex) { hedronPointIndex++; }

            // When child placements are randomized, we don't slot children into the 1st, 2nd, 3rd hedron point in order,
            // because this creates a regularity / predictability in the visual output which is not always desirable.
            // Instead, we randomize the first few placements to create some variation
            if (layoutParams.randomiseChildPlacements && hedronPointIndex > 0 && hedronPointIndex <= 20) {
                const shuffledChildIndices = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
                shuffle(shuffledChildIndices, seedrandom(`${layoutParams.randomSeed}-${p.parent}`)); // Use parent's ID as seed for shuffling, so all children use the same shuffled order

                //console.log(`Idx ${participantId}, HPI ${hedronPointIndex} -> ${shuffledChildIndices[hedronPointIndex-1]}`);
                hedronPointIndex = shuffledChildIndices[hedronPointIndex-1];
            }

            // Direction relative to parent is based on generating a point on a polyhedron centered around the parent
            const directionRelativetoParent = generateHedronPoint(hedron, hedronPointIndex);
            //console.log(`P ${participantId} PC ${parent.numPlacedChildren} HPI ${hedronPointIndex} DRP ${directionRelativetoParent}`);

            // Calculate distance from parent
            //const spacingDistance = Math.pow(layoutParams.spacingStep, parent.depth);
            //const declusteredSpacingDistance = Math.pow(layoutParams.spacingStep, parent.depth-1.0);
            
            // If "declustering" is enabled, distance from parent can be increased when we have few siblings / children
            const declusterBy = layoutParams.declusterMode == "depth" ? parent.maxChildDepth : parent.numDirectChildren;
            const declusterFactor = Math.pow( 1.0-clamp(declusterBy / (layoutParams.declusterAmount+1.0), 0.0, 1.0), layoutParams.declusterEase);
            const declusteredDepth = parent.declusteredDepth! + (1.0-declusterFactor);

            const calculateWorldPosition = (nodeSpacing: number, spacingStep: number, parentPos: vec3) => {
                // Calculate final spacing, by lerping between regular and declustered spacing based on the declustering factor
                const finalSpacing = nodeSpacing * Math.pow(spacingStep, parent.declusteredDepth!);

                // Calculate position relative to parent
                const relativePosition = vec3.create();
                vec3.scale(relativePosition, directionRelativetoParent, finalSpacing);

                // Rotate position so that we "inherit" our parent's facing direction
                vec3.transformQuat(relativePosition, relativePosition, parent.orient!);

                // Calculate final world position
                const worldPosition = vec3.create();
                return vec3.add(worldPosition, parentPos, relativePosition);
            }

            const worldPositionGlobal = calculateWorldPosition(layoutParamsGlobal.nodeSpacing, layoutParamsGlobal.spacingStep, nodes[p.parent!].globalViewPos);
            const worldPositionLocal = calculateWorldPosition(layoutParamsLocal.nodeSpacing, layoutParamsLocal.spacingStep, nodes[p.parent!].localViewPos);
            
            // Calculate orientation pointing from parent to this node - this will be used by our own children
            const orientMat = mat4.create();
            mat4.targetTo(orientMat, nodes[p.parent!].globalViewPos, worldPositionGlobal, vec3.fromValues(0,1,0));
            const orient = quat.create();
            mat4.getRotation(orient, orientMat);

            nodes.push({
                globalViewPos: worldPositionGlobal,
                localViewPos: worldPositionLocal,
                colour: vec3.fromValues(...p.colour),
                generation: declusteredDepth,
                joinedTime: participants[participantId].joined,
                parentIdx: p.parent
            });
            meta[participantId].orient = orient;
            meta[participantId].declusteredDepth = declusteredDepth;

            parent.numPlacedChildren++;

            ++participantId;
        }

        onProgress(nodes);

        
        if (processUpTo < participants.length) {
            setTimeout(processNextBatch, 0);
        } else {
            console.log(`Layout complete! Took ${new Date().getTime() - startTime.getTime()} ms`);
        }
    }

    setTimeout(indexTree, 0);

    return ()=> {
        terminated = true;
    };
}

export function calculateNodeScale(generation: number) {
    return Math.pow(layoutParams.nodeGenerationScaleFactor, generation);
}

export function calculateNodeSpreadFactor(numNodes: number) {
    const spread = numNodes * layoutParams.spreadRate;
    return 1.0 + Math.min(Math.pow(spread, layoutParams.spreadEase), spread);
}

function eulerQuat(x:number, y:number, z:number) {
    const q = quat.create();
    return quat.fromEuler(q,x,y,z);
}