import { mat4, quat, vec3 } from "gl-matrix";
import { NodeInstanceData } from "./renderers/renderer_node";
import { camera, cameraParams, layoutParams, layoutParamsGlobal, layoutParamsLocal, nodeVisualisationParams } from "./rendering_state";
import { calculateNodeScale, calculateNodeSpreadFactor } from "./node_layout/node_layout";
import { clamp, lerp } from "@/core/utils";
import { ArtworkBinding } from "./artwork";

const matrixCalculationState = {
    // Main projection-view matrix for 2D UI rendering
    projViewUI: mat4.create(),

    // Main projection-view matrix for 3D rendering
    projView3D: mat4.create(),
    
    // For intermediate calculations - we re-use these to prevent allocating each frame
    projection: mat4.create(),
    rotation: mat4.create(),
    zoomOffset: vec3.create(),
    rotateDelta: quat.create(),
}

export function calculateProjViewMatrixUI(canvas: HTMLCanvasElement) {
    mat4.ortho(matrixCalculationState.projViewUI, 0, canvas.clientWidth, 0, canvas.clientHeight, 0, 1000000);
    return matrixCalculationState.projViewUI;
}

export function calculateProjViewMatrix3D(canvas: HTMLCanvasElement, nodes: NodeInstanceData[]) {
    mat4.fromQuat(matrixCalculationState.rotation, camera.rotation);
    vec3.set(matrixCalculationState.zoomOffset, 0, 0, -camera.distance*camera.zoomMultiplier*calculateNodeSpreadFactor(clamp(nodeVisualisationParams.numNodes, 1, nodes.length)));

    mat4.identity(matrixCalculationState.projView3D);
    mat4.translate(matrixCalculationState.projView3D, matrixCalculationState.projView3D, matrixCalculationState.zoomOffset);
    mat4.multiply(matrixCalculationState.projView3D, matrixCalculationState.projView3D, matrixCalculationState.rotation);
    mat4.translate(matrixCalculationState.projView3D, matrixCalculationState.projView3D, camera.offset);
    
    mat4.perspective(matrixCalculationState.projection, (cameraParams.fov/180.0)*Math.PI, canvas.clientWidth/canvas.clientHeight, 1, 1000000);

    mat4.multiply(matrixCalculationState.projView3D, matrixCalculationState.projection, matrixCalculationState.projView3D);

    return matrixCalculationState.projView3D;
}

export function rotateCamera(deltaX: number, deltaY: number) {
    quat.fromEuler(matrixCalculationState.rotateDelta, deltaY/10, deltaX/10, 0);
    quat.mul(camera.rotation, matrixCalculationState.rotateDelta, camera.rotation);
}

export function zoomCamera(delta: number) {
    camera.distance = clamp(camera.distance * (1.0 + delta), 2000, 100000);
}

export function updateCameraTracking(myId: number, nodes: NodeInstanceData[], binding: ArtworkBinding, deltaTime: number) {
    if (nodes.length == 0) return {centerOnId: 0, isTracking: false};

    const trackId = nodes.length <= myId ? 0 : myId;

    const trackFromNode = nodes[0];
    const trackToNode = nodes[trackId];
    camera.trackRatio = lerp(camera.trackRatio, binding.centerOn == "me" ? 1.0 : 0.0, clamp(0.99*deltaTime,0,1));

    // Lerp params based on track ratio
    layoutParams.spreadRate = lerp(layoutParamsGlobal.spreadRate, layoutParamsLocal.spreadRate, camera.trackRatio);
    layoutParams.spreadEase = lerp(layoutParamsGlobal.spreadEase, layoutParamsLocal.spreadEase, camera.trackRatio);
    layoutParams.nodeSize = lerp(layoutParamsGlobal.nodeSize, layoutParamsLocal.nodeSize, camera.trackRatio);
    layoutParams.nodeGenerationScaleFactor = lerp(layoutParamsGlobal.nodeGenerationScaleFactor, layoutParamsLocal.nodeGenerationScaleFactor, camera.trackRatio);

    vec3.lerp(camera.offset, trackFromNode.globalViewPos, trackToNode.localViewPos, camera.trackRatio);
    vec3.scale(camera.offset, camera.offset, calculateNodeSpreadFactor(clamp(nodeVisualisationParams.numNodes, 1, nodes.length)));
    vec3.negate(camera.offset, camera.offset);

    const trackFromZoomMultiplier = calculateNodeScale(trackFromNode.generation);
    const trackToZoomMultiplier   = calculateNodeScale(trackToNode.generation);
    camera.zoomMultiplier = lerp(trackFromZoomMultiplier, trackToZoomMultiplier, camera.trackRatio);

    // Zoom out during transition
    const zoomOutFactor = 1.0 - Math.pow(Math.abs(camera.trackRatio - 0.5)*2.0, 2.0);
    camera.zoomMultiplier += zoomOutFactor * 0.5;

    // Limit zoom to a reasonable amount
    const overZoomedFactor = camera.distance*camera.zoomMultiplier / (nodeVisualisationParams.depthRange*1.75);
    if (overZoomedFactor > 1) {
        camera.distance /= overZoomedFactor;
    }

    return {
        centerOnId: binding.centerOn == "me" ? trackId : 0,
        isTracking: Math.abs(camera.trackRatio-0.5) < 0.495
    };
}