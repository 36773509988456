import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

import MainView from '@/views/MainView.vue';

const routes: Array<RouteRecordRaw> = [
  {path: '/', name: 'Main', component: MainView, children: []},
  {path: '/:myId', name: 'MainWithId', component: MainView, props: true},
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});

export default router;
