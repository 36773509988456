<!-- Canvas hosting the WebGL-based artwork, as well as tuning control panel -->
<template>
  <!-- WebGL canvas -->
  <canvas id="canvasElem" ref="canvasElem" @pointerup="onTimelinePointerUp($event)"></canvas>

  <div class="uiWrapper">
    <img v-if="binding?.showUi" class="studioExLogo" src="/images/logo_studioex.png"/>
    <img v-if="binding?.showUi" class="blueprintLogo" src="/images/logo_blueprint.png"/>
    <img v-if="binding?.showUi" class="sixdegreesBanner" src="/images/banner_sixdegrees.png"/>

    <div v-if="binding" class="buttonsWrapper">
      <img v-if="binding?.showUi" class="viewButton" src="/images/button_global.png"
        :class="{'active': binding.centerOn=='blueprint'}"
        @click="binding.centerOn='blueprint'"/>

      <img v-if="binding?.showUi" class="viewButton" src="/images/button_personal.png"
        :class="{'active': binding.centerOn=='me'}"
        @click="binding.centerOn='me'"/>

      <img v-if="binding?.showUi && binding.orbit == 'auto'" class="orbitButton active" src="/images/button_orbit_auto.png" @click="binding.orbit = 'off'"/>
      <img v-if="binding?.showUi && binding.orbit == 'off'" class="orbitButton" src="/images/button_orbit_off.png" @click="binding.orbit = 'on'"/>
      <img v-if="binding?.showUi && binding.orbit == 'on'" class="orbitButton active" src="/images/button_orbit.png" @click="binding.orbit = 'auto'"/>

      <img class="showUiButton" src="/images/button_showui.png"
        :class="{'active': binding.showUi}"
        @click="binding.showUi = !binding.showUi"/>
    </div>

    <div v-if="binding?.showUi" class="statsWrapper">
      <img class="statsHeader" src="/images/widget_statsheader.png"/>

      <p>
        User: <span class="paddingDigits">{{ generatePadding(binding.stats.myParticipantNumber, 8) }}</span>{{ addLeadingZeroAndCommas(binding.stats.myParticipantNumber) }}
        <br>
        {{ binding.stats.myParticipantName }}
      </p>

      <p>
        Active Time:
        <br>
        {{ calculateActiveTime(binding.timelinePosSecondsClamped) }}
      </p>

      <p>
        Total User Count:
        <br>
        <span class="paddingDigits">{{ generatePadding(binding.stats.totalParticipants, 12) }}</span>{{ addLeadingZeroAndCommas(binding.stats.totalParticipants) }}
      </p>

      <p>
        Direct Connections:
        <br>
        <span class="paddingDigits">{{ generatePadding(binding.stats.directChildren, 4) }}</span>{{ addLeadingZeroAndCommas(binding.stats.directChildren) }}
        <br>
        In-Direct Connections:
        <br>
        <span class="paddingDigits">{{ generatePadding(binding.stats.totalChildren, 9) }}</span>{{ addLeadingZeroAndCommas(binding.stats.totalChildren) }}
      </p>

      <img class="statsFooter" src="/images/widget_statsfooter.png"/>
    </div>
  </div>

  <!-- Status bar -->
  <div v-if="debugControlsEnabled" class="statusBar">

    <div v-if="binding" class="fpsLabel">{{ Math.round(binding.fps) }}</div>

    <div class="toggleControlsLabel" @click="showControls=!showControls">
      [{{ showControls ? 'Hide Controls' : 'Show Controls' }}]
    </div>

    <!-- Popup for controls -->
    <div class="controls" v-if="showControls">

      <div class="column">
        <h2>Nodes</h2>
        <label>
          Bob Amount ({{ nodeVisualisationParams.bobAmount }})<br>
          <input type="range" min="0" max="100" step="0.01" v-model.number="nodeVisualisationParams.bobAmount">
        </label>
        <br>
        <label>
          Bob Speed ({{ nodeVisualisationParams.bobSpeed }})<br>
          <input type="range" min="0" max="20" step="0.01" v-model.number="nodeVisualisationParams.bobSpeed">
        </label>
        <br>
        <label>
          Swim Amount ({{ nodeVisualisationParams.swimAmount }})<br>
          <input type="range" min="0" max="100" step="0.01" v-model.number="nodeVisualisationParams.swimAmount">
        </label>
        <br>
        <label>
          Swim Speed ({{ nodeVisualisationParams.swimSpeed }})<br>
          <input type="range" min="0" max="20" step="0.01" v-model.number="nodeVisualisationParams.swimSpeed">
        </label>
        <br>
        <label>
          Pulse Amount ({{ nodeVisualisationParams.pulseAmount }})<br>
          <input type="range" min="0" max="1" step="0.01" v-model.number="nodeVisualisationParams.pulseAmount">
        </label>
        <br>
        <label>
          Pulse Speed ({{ nodeVisualisationParams.pulseSpeed }})<br>
          <input type="range" min="0" max="20" step="0.01" v-model.number="nodeVisualisationParams.pulseSpeed">
        </label>
        <br>
        <label>
          Depth Range ({{ nodeVisualisationParams.depthRange }})<br>
          <input type="range" min="50" max="100000" step="1" v-model.number="nodeVisualisationParams.depthRange">
        </label>
        <br>
        <label>
          Depth Sharpness ({{ nodeVisualisationParams.depthSharpness }})<br>
          <input type="range" min="1" max="10" step="0.01" v-model.number="nodeVisualisationParams.depthSharpness">
        </label>
        <br>
        <label>
          # Nodes ({{ nodeVisualisationParams.numNodes }})<br>
          <input type="range" min="1" max="150000" step="1" v-model.number="nodeVisualisationParams.numNodes">
        </label>

        <br>
        <h2>Nebula</h2>
        <label>
          Intensity ({{ nebulaVisualisationParams.intensity }})<br>
          <input type="range" min="0" max="1" step="0.001" v-model.number="nebulaVisualisationParams.intensity">
        </label>
        <br>
        <label>
          Sharpness ({{ nebulaVisualisationParams.sharpness }})<br>
          <input type="range" min="0" max="10" step="0.01" v-model.number="nebulaVisualisationParams.sharpness">
        </label>
        <br>
        <label>
          Scale ({{ nebulaVisualisationParams.scale }})<br>
          <input type="range" min="1" max="20" step="0.1" v-model.number="nebulaVisualisationParams.scale">
        </label>
        <br>
        <label>
          Texture ({{ nebulaVisualisationParams.texture }})<br>
          <input type="range" min="0" max="1" step="0.01" v-model.number="nebulaVisualisationParams.texture">
        </label>
        <br>
        <label>
          Max Generation ({{ nebulaVisualisationParams.maxGeneration }})<br>
          <input type="range" min="0" max="30" step="1" v-model.number="nebulaVisualisationParams.maxGeneration">
        </label>
        <br>
        <label>
          Min Generation ({{ nebulaVisualisationParams.minGeneration }})<br>
          <input type="range" min="0" max="30" step="1" v-model.number="nebulaVisualisationParams.minGeneration">
        </label>
      </div>

      <div class="column">
        <h2>Camera</h2>
        <label>
          Orbit Speed ({{ cameraParams.orbitSpeed }})<br>
          <input type="range" min="0" max="10" step="0.01" v-model.number="cameraParams.orbitSpeed">
        </label>
        <br>
        <label>
          FOV ({{ cameraParams.fov }} degrees)<br>
          <input type="range" min="0" max="180" step="0.1" v-model.number="cameraParams.fov">
        </label>
        <br style="margin-bottom: 0.5em">

        <h2>Layout</h2>
        <label>
          Mode<br>
          <FormSelectComponent v-model="layoutParams.mode" :options="{'Random':'random', 'Fan':'fan', 'Icosahedron':'icosahedron'}"/>
        </label>
        <br style="margin-bottom: 0.5em">
        <label>
          Randomise Placements<br>
          <input type="checkbox" v-model="layoutParams.randomiseChildPlacements">
        </label>
        <br style="margin-bottom: 0.5em">
        <label>
          Random Seed<br>
          <input type="text" v-model="layoutParams.randomSeed">
        </label>
        <br style="margin-bottom: 0.5em">
        <label>
          Show Lines<br>
          <FormSelectComponent v-model="layoutParams.showLines" :options="{'None':'none', 'All':'all', 'Local':'local'}"/>
        </label>
        <br style="margin-bottom: 0.5em">
        <label>
          Show Distribution<br>
          <input type="checkbox" v-model="layoutParams.showHedron">
        </label>
        <!--
        <br style="margin-bottom: 0.5em">
        <label>
          Node Spacing ({{ layoutParams.nodeSpacing }})<br>
          <input type="range" min="0" max="20000" step="1" v-model.number="layoutParams.nodeSpacing">
        </label>
        <br>
        <label>
          Spacing Step ({{ layoutParams.spacingStep }})<br>
          <input type="range" min="0" max="1" step="0.005" v-model.number="layoutParams.spacingStep">
        </label>
        -->
        <br>
        <label>
          Decluster Mode<br>
          <FormSelectComponent v-model="layoutParams.declusterMode" :options="{'Depth':'depth', 'Siblings':'siblings'}"/>
        </label>
        <br style="margin-bottom: 0.5em">
        <label>
          Decluster Amount ({{ layoutParams.declusterAmount }})<br>
          <input type="range" min="0" max="10" step="0.005" v-model.number="layoutParams.declusterAmount">
        </label>
        <br>
        <label>
          Decluster Ease ({{ layoutParams.declusterEase }})<br>
          <input type="range" min="1" max="5" step="0.005" v-model.number="layoutParams.declusterEase">
        </label>
        <br>
        <!--
        <label>
          Node Size ({{ layoutParams.nodeSize }})<br>
          <input type="range" min="1" max="5000.0" step="1" v-model.number="layoutParams.nodeSize">
        </label>
        <br>
        <label>
          Node Gen. Scale ({{ layoutParams.nodeGenerationScaleFactor }})<br>
          <input type="range" min="0" max="1" step="0.01" v-model.number="layoutParams.nodeGenerationScaleFactor">
        </label>
        <br>
        <label>
          Spread Rate ({{ layoutParams.spreadRate }})<br>
          <input type="range" min="0" max="0.00002" step="0.0000001" v-model.number="layoutParams.spreadRate">
        </label>
        <br>
        <label>
          Spread Ease ({{ layoutParams.spreadEase }})<br>
          <input type="range" min="1" max="10.0" step="0.1" v-model.number="layoutParams.spreadEase">
        </label>
        -->
      </div>

    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArtworkBinding, initArtwork } from "./artwork"
import { nodeVisualisationParams, nebulaVisualisationParams, layoutParams, cameraParams, camera } from "./rendering_state"
import { ref, onMounted, onUnmounted, defineProps } from "vue";
import FormSelectComponent from "@/components/FormSelectComponent.vue";
import { clamp } from "@/core/utils";
import { STATIC_CONFIG } from "@/core/config";

const props = defineProps<{myId?: string}>();

const canvasElem = ref(null);

const binding = ref<ArtworkBinding|null>(null);

const showControls = ref<boolean>(false);

const searchParams = new URLSearchParams(window.location.search);

const debugControlsEnabled = STATIC_CONFIG.ENV !== 'production' || searchParams.get("debug") !== null;

// When we initialize the WebGL renderer, it returns a "binding" object which we can use to interact with it
onMounted(() => {
  initArtwork(canvasElem.value! as HTMLCanvasElement, STATIC_CONFIG.PARTICIPANTS_FILE, parseInt(props.myId || STATIC_CONFIG.DEFAULT_MY_ID)-1)
  .then((b) => {
    binding.value = b;
  });
});

onUnmounted(() => {
  if (binding.value) {
    binding.value.destroyed = true;
    binding.value = null;
  }
})

function generatePadding(n: number, desiredDigits: number) {
  const suffix = "0" + n.toString();
  if (suffix.length >= desiredDigits) return "";
  let result = "";
  let numDigits = suffix.length;
  while (numDigits < desiredDigits) {
    result = ((numDigits%3==0) ? "0," : "0") + result;
    numDigits++;
  }
  return result;
}

function addLeadingZeroAndCommas(n: number) {
  const str = "0" + n.toString();
  let result = ""
  for (let i=0; i<str.length; ++i) {
    if (i>0 && (i-str.length)%3==0) {
      result += ",";
    }
    result += str.charAt(i);
  }
  return result;
}

function calculateActiveTime(seconds: number) {
  const days = Math.floor(seconds / (60*60*24))
  seconds %= 60*60*24;

  const hours = Math.floor(seconds / (60*60))
  seconds %= 60*60;

  const minutes = Math.floor(seconds / 60)
  seconds %= 60;
  seconds = Math.floor(seconds);

  return `${days}d ${hours<10?`0${hours}`:hours}h ${minutes<10?`0${minutes}`:minutes}m ${seconds<10?`0${seconds}`:seconds}s`
}

const touches = new Map<number, PointerEvent>();
function onTimelinePointerDown(e: PointerEvent) {
  touches.set(e.pointerId, e);
}
 
function onTimelinePointerUp(e: PointerEvent) {
  touches.delete(e.pointerId);
}

function onTimelinePointerMove(e: PointerEvent) {
  if (touches.size == 1) {
    scrubTo(e.x);
  }
}

function scrubTo(clickedX: number) {
  const timelineBounds = document.getElementById("timelineElem")!.getBoundingClientRect();

  binding.value!.timelinePosSeconds = binding.value!.timelineMaxSeconds * clamp((clickedX-timelineBounds.left) / timelineBounds.width, 0, 1);
}

</script>

<style scoped>
#canvasElem {
  width: 100%;
  height: 100%;
  background-color: black;
  touch-action: none; /* Prevents browser from intercepting touch events, which would mess with artwork interaction */
}

.uiWrapper {
  width: 100%;
  height: 100%;
  position: absolute;  top: 0px;
  pointer-events: none;
  user-select: none;
}

.studioExLogo {
  height: min(13vw, 13vh, 67.5px);
  margin: max(1.25%, 1rem) max(2%, 1rem);
}

.blueprintLogo {
  height: min(13vw, 13vh, 69px);
  position: absolute;  bottom: 0%;  left: 0%;
  margin: max(1.5%, 1rem) max(2%, 1rem);
}

.sixdegreesBanner {
  height: min(2.5vw, 2.5vh, 25px);
  position: absolute;  top: 0%;  left: 50%;  translate: -50%;
  margin: max(2.5%, 2.25rem) 0px;
}

@media screen and (max-width: 900px) {
  .sixdegreesBanner {
    position: absolute;  top: min(20vw, 20vh, 95px);  left: max(2%, 1rem); translate: 0px;
    margin: 0px;
  }
}

.statsWrapper {
  position: absolute;  top: 0%;  right: 0%;
  margin: max(0.5%, 0.25rem) max(1.5%, 1rem);

  text-align: right;
  color: #bfbfbf;
  text-shadow: 0px 0px 2px #000;
  /*font-family: "Orbitron", sans-serif;*/
  font-family: "M PLUS 1 Code", monospace;
  font-size: min(3vw, 3vh, 16px);
}
.paddingDigits {
  color: #3f3f3f;
}
.statsHeader {
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  height: 3em;
}
.statsFooter {
  height: 0.75em;
}

.buttonsWrapper {
  height: min(14vw, 14vh, 80px);
  position: absolute;  bottom: 0%;  right: 0%;
  margin: max(0.75%, 0.75rem) max(2%, 1.15rem);

  pointer-events: all;
}

.viewButton {
  height: 100%;
  opacity: 50%;
}
.viewButton:hover {
  opacity: 100% !important;
}
.viewButton.active {
  opacity: 90%;
}

.showUiButton {
  height: 100%;
  opacity: 15%;
  margin-left: min(8vw, 8vh);
}
.showUiButton:hover {
  opacity: 100% !important;
}
.showUiButton.active {
  opacity: 90%;
}

.orbitButton {
  height: 80%;
  opacity: 50%;
  position: absolute; right: 0px; bottom: 125%;
}
.orbitButton:hover {
  opacity: 100% !important;
}
.orbitButton.active {
  opacity: 90%;
}

.statusBar {
  opacity: 0%;
  height: 3em;
  background-color: #000000AA;
  position: relative;
  bottom: 2em;
  padding: 0.35em 0.5em 0.35em 0.5em;
  pointer-events: all;
}

.statusBar:hover {
  opacity: 100%;
}

.fpsLabel {
  float: right;
}

.toggleControlsLabel {
  float: left;
}

.controls {
  position: absolute;
  z-index: 100;
  bottom: 4em;
  left: 1em;
  background-color: #000000AA;
  padding: 1em;
  max-height: 75vh;
  overflow-y: scroll;

  display: flex;
  flex-direction: row;
}

.column {
  padding: 1em;
}
</style>

