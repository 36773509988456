/*
 * Represents polyhedra defined by triangle meshes.
 * Supports the generation of an arbitrary number of points on the surface of these meshes
 */

import { vec3 } from "gl-matrix"

type Triangle = [number, number, number];

export type Hedron = {
    vertices: vec3[],
    triangles: Triangle[],
    normalised?: boolean,
};

export const icosahedron: Hedron = {
    vertices: [
        vec3.fromValues(0.000000, 0.000000, 1.000000),
        vec3.fromValues(0.000000, 0.000000, -1.000000),
        vec3.fromValues(0.000000, 0.894427, 0.447215),
        vec3.fromValues(-0.850651, -0.276393, -0.447214),
        vec3.fromValues(-0.850651, 0.276393, 0.447214),
        vec3.fromValues(-0.525731, 0.723608, -0.447213),
        vec3.fromValues(0.525731, -0.723608, 0.447213),
        vec3.fromValues(0.525731, 0.723608, -0.447213),
        vec3.fromValues(-0.525731, -0.723608, 0.447213),
        vec3.fromValues(0.000000, -0.894427, -0.447215),
        vec3.fromValues(0.850651, 0.276393, 0.447214),
        vec3.fromValues(0.850651, -0.276393, -0.447214),
    ],
    triangles: [
        [ 10, 11, 7,],
        [ 10, 6, 11,],
        [ 3, 4, 5,],
        [ 4, 3, 8,],
        [ 7, 5, 2,],
        [ 5, 7, 1,],
        [ 9, 1, 11,],
        [ 1, 9, 3,],
        [ 6, 8, 9,],
        [ 8, 6, 0,],
        [ 2, 0, 10,],
        [ 0, 2, 4,],
        [ 7, 11, 1,],
        [ 10, 7, 2,],
        [ 3, 5, 1,],
        [ 5, 4, 2,],
        [ 11, 6, 9,],
        [ 6, 10, 0,],
        [ 3, 9, 8,],
        [ 4, 8, 0,],
    ]
};

export const fan: Hedron = {
    vertices: [
        vec3.fromValues(-0.000000, 0.00000, -1.000000),
        vec3.fromValues(-0.850651, 0.276393, -0.447214),
        vec3.fromValues(0.850651, 0.276393, -0.447214),
        vec3.fromValues(-0.525731, -0.723608, -0.447213),
        vec3.fromValues(0.525731, -0.723608, -0.447213),
        vec3.fromValues(-0.000000, 0.894427, -0.447215),
    ],
    triangles: [
        [4, 3, 0],
        [5, 0, 1],
        [0, 5, 2],
        [3, 1, 0],
        [2, 4, 0],
    ]
};

/*
 * Returns the Nth point on the surface of the specified hedron.
 * If the hedron does not have N points, it will be subdivided until it has enough.
 */
export function generateHedronPoint(hedron: Hedron, n: number): vec3 {
    if (!hedron.normalised) {
        for (const vert of hedron.vertices) {
            vec3.normalize(vert, vert);
        }
        hedron.normalised = true;
    }

    while (n > hedron.vertices.length-1) {
        subdivideRegular(hedron);
    }

    return hedron.vertices[n];
}

/*        
 *  Subdivides a hedron by finding the mid-point of each edge,
 *  and then replacing each triangle with four smaller triangles:
 * 
 *          *                  *
 *         / \                / \     
 *        /   \              /   \       
 *       /     \    ===>    *-----*
 *      /       \          / \   / \    
 *     /         \        /   \ /   \  
 *    *-----------*      *-----*-----*
 */
function subdivideRegular(hedron: Hedron) {
    console.log(`Subdividing regular hedron of ${hedron.vertices.length} verts`);

    const subdividedTriangles: Triangle[] = [];

    const midpointVertIndices = new Map<string, number>();
    for (let triIndex=0; triIndex<hedron.triangles.length; ++triIndex) {
        const triangleVerts = hedron.triangles[triIndex];

        /*
         * First, find the midpoint of each edge
         */
        const edges: [number, number][] = [
            // Ensure vertices are ordered by index, so edges going in the opposite direction are not considered distinct
            // Otherwise, we'll generate duplicate midpoint vertices
            triangleVerts[0] < triangleVerts[1] ? [triangleVerts[0], triangleVerts[1]] : [triangleVerts[1], triangleVerts[0]],
            triangleVerts[1] < triangleVerts[2] ? [triangleVerts[1], triangleVerts[2]] : [triangleVerts[2], triangleVerts[1]],
            triangleVerts[2] < triangleVerts[0] ? [triangleVerts[2], triangleVerts[0]] : [triangleVerts[0], triangleVerts[2]],
        ];

        for (const edge of edges) {
            if (midpointVertIndices.get(`${edge}`) !== undefined) continue; // Already encountered this edge when processing an earlier triangle

            const v0 = hedron.vertices[edge[0]];
            const v1 = hedron.vertices[edge[1]];

            const midpoint = vec3.create();
            vec3.add(midpoint, v0, v1);
            vec3.normalize(midpoint, midpoint);

            midpointVertIndices.set(`${edge}`, hedron.vertices.length);
            hedron.vertices.push(midpoint);
        }

        /*
         * Then, output new triangles based on the subdivision.
         * We output them at indices spaced apart throughout the array, so that the subdivided triangles are distributed evenly in the output hedron.
         */
        subdividedTriangles[hedron.triangles.length*0 + triIndex] = [midpointVertIndices.get(`${edges[0]}`)!, midpointVertIndices.get(`${edges[1]}`)!, midpointVertIndices.get(`${edges[2]}`)!];
        subdividedTriangles[hedron.triangles.length*1 + triIndex] = [triangleVerts[0], midpointVertIndices.get(`${edges[0]}`)!, midpointVertIndices.get(`${edges[2]}`)!];
        subdividedTriangles[hedron.triangles.length*2 + triIndex] = [triangleVerts[1], midpointVertIndices.get(`${edges[0]}`)!, midpointVertIndices.get(`${edges[1]}`)!];
        subdividedTriangles[hedron.triangles.length*3 + triIndex] = [triangleVerts[2], midpointVertIndices.get(`${edges[1]}`)!, midpointVertIndices.get(`${edges[2]}`)!];
    }

    console.log(`Result has ${hedron.vertices.length} vertices`);

    hedron.triangles = subdividedTriangles;
}