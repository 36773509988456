/*
 * Main entry point for artwork / visualisation
 */

import { clamp } from "@/core/utils";
import { NodeInstanceData, createNodeRenderer } from "./renderers/renderer_node";
import { Participant, ParticipantStats, calculateParticipantStats } from "./participant_model";
import { calculateNodeScale, layoutNodes } from "./node_layout/node_layout";
import { createNodeLineRenderer } from "./renderers/renderer_node_lines";
import { createHedronRenderer } from "./renderers/renderer_hedron";
import { fan, icosahedron } from "./node_layout/hedron";
import { camera, cameraParams, layoutParams, currentTime, setCurrentTime, BACKGROUND_COLOR, nodeVisualisationParams } from "./rendering_state";
import { generateTextImage, initOpenGl, loadImage, worldToScreen } from "./renderers/renderer";
import { createNebulaRenderer } from "./renderers/renderer_nebula";
import { createNodeLocalLineRenderer } from "./renderers/renderer_node_locallines";
import { reactive } from "vue";
import { calculateProjViewMatrix3D, calculateProjViewMatrixUI, rotateCamera, updateCameraTracking, zoomCamera } from "./camera";
import { createUI } from "./gui";
import { STATIC_CONFIG } from "@/core/config";
import { mat4, vec3 } from "gl-matrix";
import { createGuiImageRenderer } from "./renderers/renderer_gui_image";

const IDLE_TIMEOUT_SECONDS = 3.0;
const PLAYBACK_SPEED = 500000;
const PLAYBACK_DELAY_SECONDS = 2;

export type ArtworkBinding = {
    // Read-only state
    fps: number,
    stats: ParticipantStats,
    timelineMaxSeconds: number,
    
    // Settable state
    timelinePosSeconds?: number,
    timelinePosSecondsClamped: number,
    timelinePlaying: boolean,
    scrubbingTrackbar: boolean,

    showUi: boolean,
    centerOn: "blueprint"|"me",
    orbit: "on"|"off"|"auto",

    destroyed: boolean,
};

export async function initArtwork(canvas: HTMLCanvasElement, participantsFile: string, myId: number): Promise<ArtworkBinding> {

    /*
     * Load participant data
     */
    console.log("Loading participant data");
    const participants = (await (await fetch(participantsFile)).json()) as Participant[];
    let nodes: NodeInstanceData[] = [];
    let terminateLayoutJob: ()=>void = ()=>{ null; };

    /*
     * Initialize WebGL
     */
    console.log("Initialising artwork renderer");
    const gl = initOpenGl(canvas);

    /*
     * Initialize renderer state
     */
    const nodeRenderer = createNodeRenderer(gl);
    const nebulaRenderer = createNebulaRenderer(gl, await loadImage("/images/noise.png"));
    const lineRenderer = createNodeLineRenderer(gl);
    const localLineRenderer = createNodeLocalLineRenderer(gl);
    const hedronRenderer = createHedronRenderer(gl);

    const blueprintLabelRenderer = createGuiImageRenderer(gl, await generateTextImage(participants[0].name), 1.0);
    const myLabelRenderer = createGuiImageRenderer(gl, await generateTextImage(participants[myId].name), 1.0);

    /*
     * Define refresh functions, which detect parameter changes and recalculate layout / viaualisation data when necessary
     */
    let renderedLayoutParams = "";
    function refreshLayoutParams() {
        // Detect layout param changes which will require a full recalculation of node positioning
        const currentParams = JSON.stringify([
            layoutParams.mode, layoutParams.randomiseChildPlacements, layoutParams.randomSeed,
            layoutParams.nodeSpacing, layoutParams.spacingStep,
            layoutParams.declusterMode, layoutParams.declusterAmount, layoutParams.declusterEase
        ]);
        
        if (currentParams != renderedLayoutParams) {
            setTimeout(() => {
                terminateLayoutJob();
                terminateLayoutJob = layoutNodes(participants, (readyNodes) => {
                    nodes = readyNodes;
                });
        }, 10);
            renderedLayoutParams = currentParams;
        }
    }

    function handleCanvasResize() {
        const pixelRatio = window.devicePixelRatio || 1;
        const desiredWidth = canvas.clientWidth * pixelRatio;
        const desiredHeight = canvas.clientHeight * pixelRatio;
        if (canvas.width != desiredWidth || canvas.height != desiredHeight) {
            canvas.width = desiredWidth;
            canvas.height = desiredHeight;
            gl!.viewport(0, 0, desiredWidth, desiredHeight);
        }
    }

    /*
     * Main render loop
     */
    const binding = reactive<ArtworkBinding>({
        fps: 0,
        stats: calculateParticipantStats(participants, myId),
        timelineMaxSeconds: new Date().getTime()/1000 - participants[0].joined,
        timelinePosSecondsClamped: 0, 
        timelinePlaying: true,
        scrubbingTrackbar: false,
        showUi: true,
        centerOn: "blueprint",
        orbit: "auto",
        destroyed: false,
    });

    let idleStartedTime = 0;
    let lastTime = 0;
    let lastTimelinePosSeconds = 0;

    // Initialize UI renderer
    const updateAndRenderUI = await createUI(gl, canvas, binding, ()=>{idleStartedTime = lastTime});

    function draw(currentFrameTimestamp: DOMHighResTimeStamp) {
        if (binding.destroyed || gl == null) {
            console.log("Artwork renderer destroyed");

            // Returning will result in the draw loop ending, all references to 'gl' being lost, and allow the WebGL context to be cleaned up
            return;
        }

        // Calculate delta time, and report FPS
        setCurrentTime(currentFrameTimestamp/1000.0);

        const deltaTime = lastTime == 0 ? 0 : currentTime-lastTime;
        binding.fps = 1/deltaTime;
        lastTime = currentTime;

        // Progress timeline
        if (binding.timelinePosSeconds === undefined) {
            binding.timelinePosSeconds = -PLAYBACK_SPEED*PLAYBACK_DELAY_SECONDS;
        }

        const timelineStep = deltaTime*PLAYBACK_SPEED;
        if (binding.timelinePlaying && !binding.scrubbingTrackbar) {
            binding.timelinePosSeconds += timelineStep;
        }
        const cutoffTime = participants[0].joined + binding.timelinePosSeconds;

        const timelinePosWasChanged = binding.timelinePosSeconds != lastTimelinePosSeconds;
        binding.timelinePosSeconds
        lastTimelinePosSeconds = binding.timelinePosSeconds;

        if (timelinePosWasChanged) {
            nodeVisualisationParams.timelinePosition = cutoffTime;
            binding.timelinePosSecondsClamped = clamp(binding.timelinePosSeconds, 0, binding.timelineMaxSeconds);
        } else {
            // Even when not playing, we increase this to allow node transition-in effects to play out
            nodeVisualisationParams.timelinePosition += timelineStep;
        }

        // Determine number of nodes to render, and calculate stats for current timeline position
        let numNodesToRender = 0;
        let totalChildren = 0;
        let directChildren = 0;
        for (const participant of participants) {
            if (participant.joined <= cutoffTime) {
                numNodesToRender++;
                if (participant.isChild) ++totalChildren;
                if (participant.isDirectChild) ++directChildren;
            } else {
                break;
            }
        }
        nodeVisualisationParams.numNodes = numNodesToRender;

        binding.stats.totalParticipants = numNodesToRender;
        binding.stats.totalChildren = totalChildren;
        binding.stats.directChildren = directChildren;
        
        // Resize / refresh if necessary
        refreshLayoutParams();
        handleCanvasResize();
        
        // Track desired focus point
        const {centerOnId, isTracking} = updateCameraTracking(myId, nodes, binding, deltaTime);
        
        // Idle camera rotation
        if (isTracking) {
            idleStartedTime = lastTime;
        }
        if (binding.orbit == "on" || (binding.orbit == "auto" && lastTime-idleStartedTime > IDLE_TIMEOUT_SECONDS)) {
            rotateCamera(2.0 * cameraParams.orbitSpeed, 0.5 * cameraParams.orbitSpeed);
        }

        // Recalculate proj-view matrix
        const projViewMatrix3D = calculateProjViewMatrix3D(canvas, nodes);
        const projViewMatrixUI = calculateProjViewMatrixUI(canvas);

        // Clear screen
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        
        // Draw nodes
        nodeRenderer.prepare(projViewMatrix3D);
        nodeRenderer.draw(nodes, participants.length, camera.trackRatio);

        // Draw lines
        if (layoutParams.showLines == "all") {
            lineRenderer.prepare(projViewMatrix3D);
            lineRenderer.draw(nodes, participants.length, camera.trackRatio);
        } else if (layoutParams.showLines == "local") {
            const maxGenerationFromFocus = binding.centerOn == "me" ? 6 : 3;
            localLineRenderer.prepare(projViewMatrix3D);
            localLineRenderer.draw(nodes, centerOnId, maxGenerationFromFocus, participants.length, camera.trackRatio);
        }

        // Draw hedrons
        if (layoutParams.showHedron && nodes.length > 1) {
            hedronRenderer.prepare(projViewMatrix3D);
            switch (layoutParams.mode) {
                case "fan":
                    hedronRenderer.draw(icosahedron, nodes[0].globalViewPos, 1.0);
                    hedronRenderer.draw(fan, nodes[1].globalViewPos, calculateNodeScale(1));
                    break;
                case "icosahedron":
                    hedronRenderer.draw(icosahedron, nodes[0].globalViewPos, 1.0);
                    hedronRenderer.draw(icosahedron, nodes[1].globalViewPos, calculateNodeScale(1));
                    break;
            }
        }

        // Draw nebula
        nebulaRenderer.prepare(projViewMatrix3D);
        nebulaRenderer.draw(nodes, participants.length, camera.trackRatio);

        // Draw node labels
        const unrotateMat = mat4.create();
        mat4.fromQuat(unrotateMat, camera.rotation);
        mat4.invert(unrotateMat, unrotateMat);

        function renderParticipantLabel(idx: number, renderer: ReturnType<typeof createGuiImageRenderer>) {
            if (Math.min(nodes.length, numNodesToRender) > idx) {
                // Calculate position of label, directly underneath the node in screen-space
                const worldPos = vec3.fromValues(0, -layoutParams.nodeSize * calculateNodeScale(nodes[idx].generation) * 0.5, 0);
                vec3.transformMat4(worldPos, worldPos, unrotateMat);
                vec3.add(worldPos, worldPos, nodes[idx].globalViewPos);

                const screenPos = worldToScreen(worldPos, projViewMatrix3D, canvas);
                if (!screenPos) return; // Off-screen!

                screenPos[1] -= 15;

                renderer.prepare(projViewMatrixUI);
                renderer.draw(screenPos, 1.0);
            }
        }
        if (binding.showUi) {
            renderParticipantLabel(0, blueprintLabelRenderer);
            if (myId != 0) { renderParticipantLabel(myId, myLabelRenderer); }
        }

        // Draw UI
        updateAndRenderUI();

        // Queue up the next frame to be rendered
        requestAnimationFrame(draw);
    }

    /*
     * Trigger first frame
     */
    requestAnimationFrame(draw);

    return binding;
}