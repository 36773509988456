import seedrandom from "seedrandom";

/*
 * General-purpose utility functions
 */
export function clamp(x: number, min?: number, max?: number) {
    if (min !== undefined) { x = Math.max(min, x); }
    if (max !== undefined) { x = Math.min(max, x); }
    return x;
}

export function lerp(from: number, to: number, factor: number) {
  return from + (to-from)*factor;
}

export function shuffle<T>(array: T[], rng: seedrandom.PRNG) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(rng.double() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  