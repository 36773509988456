/*
 * Main App component - just hosts the router view where actual content will be injected, and manages loading of the app's global config
 */

<template>
  <div class="appContainer">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss">
@import "./assets/variables.scss";

// Remove margin etc to give us a container for our app which covers the entire viewport

html,
body {
  height: 100%;
  margin: 0px;
  background-color: black;
  color: #7f7f7f;
}

#app {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.appContainer {
  height: 100%;
  overflow: hidden;
}

</style>