/*
 * The core logical model of the artwork
 */

export type Participant = {
    name: string,
    colour: [number,number,number],
    joined: number,
    parent: number|null,

    isChild?: boolean,
    isDirectChild?: boolean,
}

export type ParticipantStats = {
    myParticipantNumber: number,
    myParticipantName: string,
    totalParticipants: number,

    directChildren: number,
    totalChildren: number,
}

export function calculateParticipantStats(participants: Participant[], myParticipantId: number): ParticipantStats {
    
    let directChildren = 0;
    let totalChildren = 0;
    for (let id=0; id<participants.length; ++id) {
        let parentId = participants[id].parent;

        if (parentId == myParticipantId) {
            participants[id].isDirectChild = true;
            participants[id].isChild = true;
            ++directChildren;
            ++totalChildren;
        } else {
            while (parentId != null) {
                parentId = participants[parentId].parent;
                if (parentId == myParticipantId) {
                    participants[id].isChild = true;
                    ++totalChildren;
                    break;
                }
            }
        }
    }

    return {
        myParticipantNumber: myParticipantId+1,
        myParticipantName:   participants[myParticipantId].name,
        totalParticipants:   participants.length,
        directChildren:      directChildren,
        totalChildren:       totalChildren,
    }
}