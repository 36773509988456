 <template>
    <select
    class="selectField"
    v-model="boundValue"
    >
    <option v-if="allowNullSelection || modelValue == null || modelValue == undefined" :value="undefined">{{ nullSelectionLabel || 'Select...' }}</option>
    <option
        v-for="(optionValue, optionLabel) in options"
        :key="optionValue"
        :value="optionValue"
    >{{ optionLabel }}</option>
    </select>
  </template>
  
  <script lang="ts" setup>
  import { ref, watch, defineProps, defineEmits } from "vue";
  
  const props = defineProps<{
    options: object,
    modelValue: any|null,
    allowNullSelection?: boolean,
    nullSelectionLabel?: string
  }>();
  
  // Have to bind to the <select> indirectly through this variable, as we can't use it in v-model directly
  // Also, the <select> element doesn't let us associate a 'null' value with an <option> - only 'undefined' - so we need to convert between them
  const boundValue = ref<any|undefined>(props.modelValue != null ? props.modelValue : undefined);
  
  // Event for two-way binding
  const emit = defineEmits(['update:modelValue'])
  watch(boundValue, (val) => {
    emit('update:modelValue', val == undefined ? null : val);
  });
  
  watch(()=>props.modelValue, () => {
    boundValue.value = props.modelValue != null ? props.modelValue : undefined;
  });
  
  </script>
  
  <style>
  </style>
   