import { mat4, vec3, vec4 } from "gl-matrix";
import { Renderer, VertexAttributeType, bindVertexAttributes, compileProgram, compileShader, enableBlendSettings, getVertexAttributesTotalFloatCount } from "./renderer";

export function createGuiLineRenderer(gl: WebGL2RenderingContext): Renderer<(start: vec3, end: vec3, colour: vec4)=>void> {
    const vertexShader = compileShader(
        gl, 
        `#version 300 es
        // Standard global uniforms
        uniform mat4 uProjViewMat;

        // Shared
        in vec3 aVertexPosition;
        
        void main(){
            gl_Position = uProjViewMat * vec4(aVertexPosition, 1.0);
        }
        `,
        gl.VERTEX_SHADER
    );

    const fragmentShader = compileShader(
        gl,
        `#version 300 es
        precision highp float;

        uniform vec4 uLineColour;

        out vec4 fragColour;
        
        void main() {
            fragColour = uLineColour;
        }
        `,
        gl.FRAGMENT_SHADER
    );

    const program = compileProgram(gl, vertexShader, fragmentShader);

    const uProjViewMat = gl.getUniformLocation(program, "uProjViewMat");
    const uLineColour = gl.getUniformLocation(program, "uLineColour");

    const vertexAttributes = [
        {location: gl.getAttribLocation(program, "aVertexPosition"), type: VertexAttributeType.VEC3F}
    ];
    const floatsPerVertex = getVertexAttributesTotalFloatCount(vertexAttributes);

    const lineVertexBuffer = gl.createBuffer();
    if (!lineVertexBuffer) throw new Error(`Failed to create new instanced vertex buffer`);
    const vertexData = new Float32Array(floatsPerVertex*2);

    return {
        prepare: (projViewMat: mat4) => {
            enableBlendSettings(gl, "alpha", false, false);
        
            gl.useProgram(program);
            gl.uniformMatrix4fv(uProjViewMat, false, projViewMat);
            bindVertexAttributes(gl, lineVertexBuffer, false, vertexAttributes);
        },
        draw: (start: vec3, end: vec3, colour: vec4) => {
            gl.uniform4fv(uLineColour, colour);

            vertexData.set(start, 0);
            vertexData.set(end, floatsPerVertex);
            gl.bufferData(gl.ARRAY_BUFFER, vertexData, gl.STATIC_DRAW);
            gl.drawArrays(gl.LINES, 0, 2);
        }
    };
}