import { mat4, vec3, vec4 } from "gl-matrix";
import { Hedron } from "../node_layout/hedron";
import { BACKGROUND_COLOR, layoutParams, nodeVisualisationParams } from "../rendering_state";
import { Renderer, VertexAttributeType, bindVertexAttributes, compileProgram, compileShader, enableBlendSettings } from "./renderer";

export function createHedronRenderer(gl: WebGL2RenderingContext): Renderer<(hedron:Hedron, pos: vec3, scale: number)=>void> {
    const vertexShader = compileShader(
        gl, 
        `#version 300 es

        // Standard global uniforms
        uniform mat4 uProjViewMat;
        uniform float uSpreadFactor;

        // Uniforms
        uniform vec3 uWorldPosition;
        uniform float uScale;

        // Shared
        in vec3 aVertexPosition;

        void main(){
            gl_Position = uProjViewMat * vec4(((aVertexPosition*uScale)+uWorldPosition)*uSpreadFactor, 1.0);
        }
        `,
        gl.VERTEX_SHADER
    );

    const fragmentShader = compileShader(
        gl,
        `#version 300 es
        precision highp float;

        uniform vec4 uLineColour;
        uniform vec4 uBackgroundColor;
        uniform float uDepthRange;
        uniform float uDepthSharpness;

        out vec4 fragColour;
        
        void main() {
            /*
             * Blend from the line's original colour, towards the scene's background colour, as depth increases.
             * This creates a fog / brightness falloff effect
             */
            float depthFactor = pow(clamp(gl_FragCoord.w*uDepthRange, 0.0, 1.0), uDepthSharpness);
            vec4 colourWithFalloff = mix(uBackgroundColor, uLineColour, depthFactor);
            
            fragColour = colourWithFalloff;
        }
        `,
        gl.FRAGMENT_SHADER
    );

    const program = compileProgram(gl, vertexShader, fragmentShader);

    const uProjViewMat = gl.getUniformLocation(program, "uProjViewMat");
    const uSpreadFactor = gl.getUniformLocation(program, "uSpreadFactor");
    const uWorldPosition = gl.getUniformLocation(program, "uWorldPosition");
    const uScale = gl.getUniformLocation(program, "uScale");

    const uLineColour = gl.getUniformLocation(program, "uLineColour");
    const uBackgroundColor = gl.getUniformLocation(program, "uBackgroundColor");
    const uDepthRange = gl.getUniformLocation(program, "uDepthRange");
    const uDepthSharpness = gl.getUniformLocation(program, "uDepthSharpness");

    const aVertexPosition = gl.getAttribLocation(program, "aVertexPosition");

    const lineVertexBuffer = gl.createBuffer();
    if (!lineVertexBuffer) throw new Error(`Failed to create new instanced vertex buffer`);
    let initializedInstanceData: Hedron|null = null;
    let initializedPos: vec3|null = null;

    return {
        prepare: (projViewMat: mat4) => {
            enableBlendSettings(gl, "alpha", true, true);
        
            gl.useProgram(program);

            gl.uniform4fv(uBackgroundColor, BACKGROUND_COLOR);
            gl.uniform4fv(uLineColour, vec4.fromValues(1,1,0,1));

            gl.uniformMatrix4fv(uProjViewMat, false, projViewMat);
        
            bindVertexAttributes(gl, lineVertexBuffer, false, [
                {location: aVertexPosition, type: VertexAttributeType.VEC3F},
            ]);
        },
        draw: (hedron: Hedron, pos: vec3, scale: number) => {
            if (hedron != initializedInstanceData || pos != initializedPos) {
                const myOrientMat = mat4.create();
                if (pos.every((v)=>v==0)) {
                    mat4.identity(myOrientMat);
                } else {
                    mat4.targetTo(myOrientMat, vec3.fromValues(0,0,0), pos, vec3.fromValues(0,1,0));
                }

                const vertexData = new Float32Array(3*6 * hedron.triangles.length);
                let offset = 0;
                for (const tri of hedron.triangles) {
                    const v0 = vec3.clone(hedron.vertices[tri[0]]);  vec3.transformMat4(v0, v0, myOrientMat);
                    const v1 = vec3.clone(hedron.vertices[tri[1]]);  vec3.transformMat4(v1, v1, myOrientMat);
                    const v2 = vec3.clone(hedron.vertices[tri[2]]);  vec3.transformMat4(v2, v2, myOrientMat);
                    vertexData.set(v0, offset);    offset += 3;
                    vertexData.set(v1, offset);    offset += 3;
                    vertexData.set(v1, offset);    offset += 3;
                    vertexData.set(v2, offset);    offset += 3;
                    vertexData.set(v2, offset);    offset += 3;
                    vertexData.set(v0, offset);    offset += 3;
                }

                gl.bufferData(gl.ARRAY_BUFFER, vertexData, gl.STATIC_DRAW);

                initializedInstanceData = hedron;
                initializedPos = pos;
            }

            gl.uniform1f(uScale, layoutParams.nodeSpacing*scale);
            gl.uniform3fv(uWorldPosition, pos);

            const numLinesToDraw = hedron.triangles.length * 3;
            if (numLinesToDraw <= 0) return;
            
            gl.uniform1f(uSpreadFactor, 1.0); // Spread nodes out as total number increases

            gl.uniform1f(uDepthRange, nodeVisualisationParams.depthRange);
            gl.uniform1f(uDepthSharpness, nodeVisualisationParams.depthSharpness);

            gl.drawArrays(gl.LINES, 0, numLinesToDraw*2);
        }
    };
}