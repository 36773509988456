/*
 * Main entry-point - initializes and mounts the Vue app
 */

import App from '@/App.vue';
import router from '@/router';
import { createApp } from 'vue';
import { STATIC_CONFIG } from './core/config';

console.log("The Six Degrees - Version " + STATIC_CONFIG.VERSION);

// Init styles
require('@/assets/styles.scss');

// Init Vue
const app = createApp(App);
app.use(router).mount('#app');