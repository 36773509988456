import { mat4, vec3 } from "gl-matrix";
import { QUAD_VERTEX_BUFFER, Renderer, VertexAttributeType, bindVertexAttributes, compileProgram, compileShader, enableBlendSettings } from "./renderer";

export function createGuiImageRenderer(gl: WebGL2RenderingContext, image: TexImageSource, scale:number): Renderer<(position:vec3, alpha:number)=>void> {
    const vertexShader = compileShader(
        gl, 
        `#version 300 es
        // Standard global uniforms
        uniform mat4 uProjViewMat;

        // Shared
        in vec3 aVertexPosition;
        in vec2 aVertexUV;
        uniform vec3 uImageSize;
        uniform vec3 uImagePosition;

        out vec2 vUV;

        void main(){
            // Scale and offset the image to its appropriate world position,
            // then counter-rotate it so it always faces the camera
            vec3 pos = aVertexPosition*uImageSize + uImagePosition;
            gl_Position = uProjViewMat * vec4(pos, 1.0);

            // Just pass the UV coordinates through
            vUV = aVertexUV;
        }
        `,
        gl.VERTEX_SHADER
    );

    const fragmentShader = compileShader(
        gl,
        `#version 300 es
        precision highp float;

        uniform sampler2D uTexture;

        in vec2 vUV;
        uniform float uImageAlpha;

        out vec4 fragColour;

        void main() {
            fragColour = texture(uTexture, vUV);
            fragColour.w *= uImageAlpha;
        }
        `,
        gl.FRAGMENT_SHADER
    );

    const program = compileProgram(gl, vertexShader, fragmentShader);

    const uProjViewMat = gl.getUniformLocation(program, "uProjViewMat");

    const uImageSize = gl.getUniformLocation(program, "uImageSize");
    const uTexture = gl.getUniformLocation(program, "uTexture");
    const uImagePosition = gl.getUniformLocation(program, "uImagePosition");
    const uImageAlpha = gl.getUniformLocation(program, "uImageAlpha");

    const vertexAttributes = [
        {location: gl.getAttribLocation(program, "aVertexPosition"), type: VertexAttributeType.VEC2F},
        {location: gl.getAttribLocation(program, "aVertexUV"),       type: VertexAttributeType.VEC2F},
    ];

    const unrotateMat = mat4.create();

    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);

    return {
        prepare: (projViewMat: mat4) => {
            enableBlendSettings(gl, "alpha", false, false);
        
            gl.useProgram(program);

            gl.uniformMatrix4fv(uProjViewMat, false, projViewMat);

            gl.uniform3fv(uImageSize, [image.width*scale, image.height*scale, 1.0]);

            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_2D, texture);
            gl.uniform1i(uTexture, 0);

            bindVertexAttributes(gl, QUAD_VERTEX_BUFFER!, false, vertexAttributes);
        },
        draw: (position: vec3, alpha: number) => {
            gl.uniform3fv(uImagePosition, position);
            gl.uniform1f(uImageAlpha, alpha);
            gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);
        }
    };
}